import React from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';

const StyledButtonGroup = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  grid-area: 1 / 1 / 4 / 2;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-area: 2 / 2 / 2 / 2;
    gap: var(${({ theme }) => theme.responsive.spacing.xxs});

    padding: var(${({ theme }) => theme.responsive.spacing.m})
      var(${({ theme }) => theme.responsive.spacing.pageMargin})
      var(${({ theme }) => theme.responsive.spacing.xxl});
  }
`;

export interface NavigationButtonGroupProps {
  readonly children?: ReactNode;
}

export const NavigationButtonGroup: FC<NavigationButtonGroupProps> = ({ children }) => (
  <StyledButtonGroup>{children}</StyledButtonGroup>
);
