import { Icon } from '@audi/audi-ui-react';
import React, { useContext, type FC } from 'react';
import styled from 'styled-components';
import { UniversalStateContext } from '../../context/UniversalStateContext';

type Direction = 'back' | 'forward';

const StyledNavigationButton = styled.button<{ visible: boolean; direction: Direction }>`
  appearance: none;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);

  border: none;
  border-radius: 50%;
  margin-inline: -18px;
  padding: 24px 16px 8px;
  transform: ${({ direction }) => (direction === 'back' ? 'rotate(-90deg)' : 'rotate(90deg)')};
  pointer-events: auto;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  &:not(:disabled):hover {
    cursor: pointer;
  }

  & > svg {
    color: white;
    display: block;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding: 16px;
    margin-inline: 0;
    visibility: visible;
    background: ${({ visible }) => (visible ? 'black' : 'rgba(255, 255, 255, 0.1)')};

    &:not(:disabled):hover {
      background: var(${({ theme }) => theme.colors.interaction.primary.hover});
    }

    & > svg {
      color: var(${({ theme, disabled }) => (disabled ? theme.colors.interaction.tertiary.disabled : theme.colors.base.brand.white)});
  }
`;

export interface NavigationButtonProps {
  readonly onClick: () => void;
  readonly direction: Direction;
  readonly disabled?: boolean;
  readonly visible?: boolean;
}

export const NavigationButton: FC<NavigationButtonProps> = ({
  onClick,
  direction,
  disabled = false,
  visible = true,
}) => {
  const universalState = useContext(UniversalStateContext);

  return (
    <StyledNavigationButton
      onClick={onClick}
      visible={visible}
      disabled={disabled}
      direction={direction}
      aria-label={
        direction === 'back'
          ? universalState?.i18nTexts.previousButtonText
          : universalState?.i18nTexts.nextButtonText
      }
    >
      <Icon name="arrow-straight-up" size="xs" variant="secondary" />
    </StyledNavigationButton>
  );
};
