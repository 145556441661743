import React, { forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { useQuery } from '@oneaudi/onegraph-client';
import type {
  CarlineQuery,
  CarlineQueryVariables,
} from '../../graphql/documents/carline.generated';
import { CarlineDocument } from '../../graphql/documents/carline.generated';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';

const Wrapper = styled.div`
  width: 60vw;
  max-width: 1152px;
  min-width: 300px;
  aspect-ratio: 4 / 7;
  align-self: end;
  scroll-snap-align: center;
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    aspect-ratio: 2 / 3;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    aspect-ratio: 5 / 6;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    aspect-ratio: 19 / 20;
    // width needs to be capped because of the max width of 1920px. 52vw at 1920px is 998.4px
    width: min(52vw, 998.4px);
  }
`;

const CarImage = styled.img`
  height: auto;
  min-width: 120%;
  margin-left: -10%;
  margin-bottom: 85%;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    margin-bottom: 70%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-bottom: 38%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-bottom: 33%;
  }
`;

const carImageWidths = [360, 720, 1024, 1400];

export interface CarImageWrappeProps {
  readonly carlineId: string;
}

export const CarImageWrapper = forwardRef<HTMLDivElement, CarImageWrappeProps>(
  ({ carlineId }, ref) => {
    const { featureServices } = useFeatureAppEnv();
    const { countryCode, language } = featureServices['gfa:locale-service'];
    const { data } = useQuery<CarlineQuery, CarlineQueryVariables>(CarlineDocument, {
      variables: {
        identifier: {
          brand: 'A',
          id: carlineId,
          country: countryCode,
          language,
        },
      },
    });

    const { breakpoints } = useTheme();
    const imageUrl = data?.carline.exteriorViews?.beautyFront;

    return (
      <Wrapper ref={ref}>
        {imageUrl && (
          <CarImage
            src={imageUrl}
            srcSet={carImageWidths
              .map((width) => {
                const url = new URL(imageUrl);

                url.searchParams.set('width', String(width));
                url.searchParams.set('mimetype', 'image/webp');

                return `${url.toString()} ${width}w`;
              })
              .join(', ')}
            sizes={[
              // CarImageWrapper__Wrapper: width 52vw
              // CarImageWrapper__CarImage: min-width 120% => width 62.4vw
              `(min-width: ${breakpoints.l}px) 62.4vw`,
              // CarImageWrapper__Wrapper: width 60vw
              // CarImageWrapper__CarImage: min-width 120% => width 72vw
              `(min-width: ${breakpoints.s}px) 72vw`,
              // CarImageWrapper__Wrapper: min-width 300px
              // CarImageWrapper__CarImage: min-width 120% => width 360px
              '360px',
            ].join(', ')}
            loading="lazy"
            alt={data?.carline.name}
          />
        )}
      </Wrapper>
    );
  },
);
