import { Text } from '@audi/audi-ui-react';
import { UeElement } from '@oneaudi/falcon-tools';
import type { FC } from 'react';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { CarlineBlock } from './components/CarlineBlock';
import { ConsumptionAndEmission } from './components/ConsumptionAndEmission';
import { Header } from './components/Header';
import { NavigationButton } from './components/button/NavigationButton';
import { NavigationButtonGroup } from './components/button/NavigationButtonGroup';
import { ImageSlider } from './components/slideshow/ImageSlider';
import { ContentContext } from './context/ContentContext';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ImageOverlay = styled.div`
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content max-content;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-template-columns: 1fr max-content;
    grid-template-rows: min-content max-content;
    align-content: end;
  }
`;

const PageIndicator = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: block;
  }
`;

export const FeatureApp: FC = () => {
  const content = useContext(ContentContext);
  const [visibleVehicleIndex, setVisibleVehicleIndex] = useState(0);

  if (!content) {
    return null;
  }

  return (
    <>
      <Header />
      <Container>
        <ImageSlider
          visibleVehicleIndex={visibleVehicleIndex}
          setVisibleVehicleIndex={setVisibleVehicleIndex}
        />
        <ImageOverlay>
          <NavigationButtonGroup>
            <PageIndicator>
              <Text variant="copy2" spaceInlineEnd="s">
                {visibleVehicleIndex + 1}/{content.carlines.length}
              </Text>
            </PageIndicator>
            <NavigationButton
              direction="back"
              onClick={() => {
                setVisibleVehicleIndex((index) => index - 1);
              }}
              visible={visibleVehicleIndex > 0}
              disabled={visibleVehicleIndex === 0}
            />
            <NavigationButton
              direction="forward"
              onClick={() => {
                setVisibleVehicleIndex((index) => index + 1);
              }}
              visible={visibleVehicleIndex < content.carlines.length - 1}
              disabled={visibleVehicleIndex === content.carlines.length - 1}
            />
          </NavigationButtonGroup>
          <CarlineBlock carlineId={content.carlines[visibleVehicleIndex]} />
        </ImageOverlay>
      </Container>
      {content.caeDisplayOption_showCae && (
        <ConsumptionAndEmission carlineId={content.carlines[visibleVehicleIndex]} />
      )}
      {content.disclaimer && (
        <Text variant="copy2" tint="secondary" spacing={['m', 'pageMargin']}>
          <UeElement type="text" property="disclaimer" label="Disclaimer">
            {content.disclaimer}
          </UeElement>
        </Text>
      )}
    </>
  );
};
