import { Button, Text } from '@audi/audi-ui-react';
import { UeElement } from '@oneaudi/falcon-tools';

import React, { useContext } from 'react';
import styled from 'styled-components';
import { ContentContext } from '../context/ContentContext';

const HeaderContainer = styled.div`
  text-align: center;
  padding-block: var(${(props) => props.theme.responsive.spacing.xxxl})
    var(${(props) => props.theme.responsive.spacing.xxl});
  padding-inline: var(${(props) => props.theme.responsive.spacing.pageMargin});

  > h2 {
    color: black !important;
  }
`;

export const Header = () => {
  const content = useContext(ContentContext);

  if (!content) {
    return null;
  }

  return (
    <HeaderContainer>
      <Text variant="order2" as="h2">
        <UeElement type="text" property="headline" label="Headline">
          {content.headline}
        </UeElement>
      </Text>
      <Button variant="text" href={content.link_url} spaceStackStart="s">
        <UeElement type="text" property="link_text" label="Link text">
          {content.link_text}
        </UeElement>
      </Button>
    </HeaderContainer>
  );
};
