import { Button, Text } from '@audi/audi-ui-react';
import React, { useContext, useState, type FC } from 'react';
import styled from 'styled-components';
import { UniversalStateContext } from '../context/UniversalStateContext';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';
import type { CarlineQuery } from '../../graphql/documents/carline.generated';

const StyledActionWrapper = styled.div`
  display: grid;
  width: 100%;
  margin-block-start: var(${(props) => props.theme.responsive.spacing.l});
  position: relative;

  grid-template-areas:
    'flyout'
    'button-group';

  grid-template-rows: 0 auto;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    min-width: 305px;
    width: fit-content;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    min-width: 350px;
  }
`;

const StyledButtonWrapper = styled.div<{ hasConfigureLink: boolean; hasVTPButton: boolean }>`
  grid-area: button-group;
  pointer-events: auto;
  display: grid;
  gap: var(${(props) => props.theme.responsive.spacing.xxs});

  grid-template-columns: ${(props) =>
    `repeat(${props.hasConfigureLink ? 2 : 1}, 1fr) ${props.hasVTPButton ? 'max-content' : ''}`};
`;

const VTPButton = styled(Button)<{ flyoutOpen: boolean }>`
  ${({ flyoutOpen }) => (flyoutOpen ? 'background: white;' : '')}
  ${({ flyoutOpen }) => (flyoutOpen ? 'box-shadow: none !important;' : '')}
`;

const Flyout = styled.div<{ actionsFlyoutIsVisible: boolean }>`
  grid-area: flyout;
  position: absolute;
  top: calc(var(${(props) => props.theme.responsive.spacing.xxs}) * -1);
  width: 100%;
  height: fit-content;
  display: block;
  box-sizing: border-box;
  bottom: 100%;
  padding: var(${(props) => props.theme.responsive.spacing.l});
  transform: ${(props) => (props.actionsFlyoutIsVisible ? 'translateY(-100%)' : 'translateY(0)')};
  transition:
    transform 0.25s ease-in-out,
    opacity 0.25s ease-in-out;
  opacity: ${(props) => (props.actionsFlyoutIsVisible ? 1 : 0)};
  background-color: var(${(props) => props.theme.colors.base.brand.white});
  pointer-events: ${(props) => (props.actionsFlyoutIsVisible ? 'auto' : 'none')};
`;

export interface ActionWrapperProps {
  readonly carlineQuery: CarlineQuery;
}

export const ActionWrapper: FC<ActionWrapperProps> = ({ carlineQuery: { carline } }) => {
  const { featureServices } = useFeatureAppEnv();
  const numberFormatter = featureServices['audi-number-formatter-service'];
  const universalState = useContext(UniversalStateContext);
  const [actionsFlyoutIsVisible, setActionsFlyoutIsVisible] = useState(false);
  const newCars = carline.availableStock?.newCars;
  const usedCars = carline.availableStock?.usedCars;
  const newCarsAmount = newCars?.amount ?? 0;
  const usedCarsAmount = usedCars?.amount ?? 0;
  const availableStock = newCarsAmount + usedCarsAmount > 0;

  if (!carline.links) {
    return null;
  }

  return (
    <StyledActionWrapper>
      {availableStock && (
        <Flyout actionsFlyoutIsVisible={actionsFlyoutIsVisible}>
          <Text variant="copy2" spaceStackEnd="m">
            {universalState?.i18nTexts.vtpHeadline.replace(
              // eslint-disable-next-line no-template-curly-in-string
              '${value}',
              carline.name,
            )}
          </Text>
          <Button
            href={newCars!.url}
            disabled={newCarsAmount === 0}
            variant="secondary"
            size="small"
            stretch
            spaceStackEnd="xs"
          >
            {universalState?.i18nTexts.vtpNewCarsLinkText.replace(
              // eslint-disable-next-line no-template-curly-in-string
              '${value}',
              numberFormatter.formatNumber(newCarsAmount),
            )}
          </Button>
          <Button
            href={usedCars?.url}
            disabled={usedCarsAmount === 0}
            variant="secondary"
            size="small"
            stretch
          >
            {universalState?.i18nTexts.vtpUsedCarsLinkText.replace(
              // eslint-disable-next-line no-template-curly-in-string
              '${value}',
              numberFormatter.formatNumber(usedCarsAmount),
            )}
          </Button>
        </Flyout>
      )}
      <StyledButtonWrapper
        hasConfigureLink={!!carline.links?.configuratorPage}
        hasVTPButton={availableStock}
      >
        {carline.links?.configuratorPage?.url && (
          <Button variant="primary" size="small" href={carline.links.configuratorPage.url}>
            {universalState?.i18nTexts.configureLinkText}
          </Button>
        )}
        <Button variant="secondary" size="small" href={carline.links.carlineStartPage!.url!}>
          {universalState?.i18nTexts.discoverLinkText}
        </Button>
        {availableStock && (
          <VTPButton
            variant="icon-secondary"
            size="small"
            icon={actionsFlyoutIsVisible ? 'cancel' : 'shopping-basket'}
            onClick={() => setActionsFlyoutIsVisible(!actionsFlyoutIsVisible)}
            flyoutOpen={actionsFlyoutIsVisible}
          />
        )}
      </StyledButtonWrapper>
    </StyledActionWrapper>
  );
};
